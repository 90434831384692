<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>报名统计</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="20">
                            <el-form :inline="true">
                                <el-form-item label="关键字">
                                    <el-input v-model="queryParams.keyword" placeholder="请输入查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item label="报名次数">
                                    <el-input v-model="queryParams.limitTimes" placeholder="请输入报名次数"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="姓名" prop="trueName" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="身份证号" prop="idCard" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="手机号" prop="phone" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="报名次数" prop="userCount" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini" @click="showDtl(row.idCard)">报名明细</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="报名明细"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="1000px">
            <el-row>
                <e-col>
                    <el-table
                        v-loading="tableLoading"
                        :data="dtlData"
                        fit
                        stripe
                        highlight-current-row
                        style="width: 100%;"
                        ref="queryTable"
                        :indent="20"
                    >
                        <el-table-column label="姓名" prop="trueName" min-width="140px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="身份证号" prop="idCard" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="手机号" prop="phone" min-width="140px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="工种" prop="gnvqName" min-width="120px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="等级" prop="levelName" min-width="80px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="报名时间" prop="createTime" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </e-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    export default {
        components: {
            Pagination,
            Breadcrumb,
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '报名统计'
                    }
                ],
                //列表查询数据
                queryParams: {
                    keyword: '',
                    limitTimes: '',
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    startDate: '',
                    endDate: '',
                    payWay: '',
                    payStatus: '',
                    orgId: this.appCookies.getCookieOID(),
                    userId: this.appCookies.getCookieUID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //明系列表
                dtlData: [],
                //编辑、新增表单
                editDialog: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('edumgr/queryUserCount', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            showDtl: function (idCard) {
                this.apis.postForm('edumgr/getUserDtl', { idCard: idCard })
                    .then((res) => {
                        if (res.code === 1) {
                            this.dtlData = res.data;
                            this.editDialog = true;
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
