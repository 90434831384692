<template>
    <div class="home">
        <div id="editor2"></div>
    </div>
</template>

<script>
    import Editor from 'wangeditor'
    export default {
        props: {
            height: {
                type: Number,
                default: 150
            }
        },
        data() {
            return {
                editor: null
            }
        },
        methods: {
            init() {
                const _this = this;
                this.editor = new Editor('#editor2');
                this.setMenus(); // 设置菜单
                this.setImageUpload();
                this.editor.config.height = 150;
                // 配置 onchange 回调函数
                this.editor.config.onchange = function (newHtml) {
                    // console.log('change 之后最新的 html', newHtml)
                    // _this.$emit('changeHtml', this.info_) // 将内容同步到父组件中
                };
                // 配置触发 onchange 的时间频率，默认为 200ms
                _this.editor.config.onchangeTimeout = 1000; // 修改为 500ms

                this.editor.create(); // 创建编辑器
            },
            setMenus() {
                this.editor.config.menus = [
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'fontName', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'strikeThrough', // 删除线
                    'indent',
                    'lineHeight',
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'link', // 插入链接
                    'list', // 列表
                    'todo',
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'image', // 插入图片
                    'video', // 插入视频
                    'table', // 表格
                    'code', // 插入代码
                    'splitLine',
                    'undo', // 撤销
                    'redo', // 重复
                    'fullscreen' // 全屏
                ]
            },
            setImageUpload() {
                // this.editor.config.showLinkImg = false; // 关闭网络路径图片方式
                this.editor.config.uploadImgServer = process.env.VUE_APP_API_HOST + 'system/file/uploadFile'; // 后台请求地址
                this.editor.config.uploadImgHeaders = {
                    'Authorization': 'bearer ' + this.appCookies.getCookieAccessToken() // 添加后台鉴权需要使用的token
                }
                // 关闭粘贴样式的过滤
                this.editor.config.pasteFilterStyle = false;
                // 忽略粘贴内容中的图片
                this.editor.config.pasteIgnoreImg = true;
                this.editor.config.uploadFileName = 'file'; // 文件名
                this.editor.config.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
                this.editor.config.debug = true;
                this.editor.config.uploadImgHooks = {
                    customInsert: function (insertImg, result, editor) {
                        console.log(result)
                        if (result.code === 1) {
                            let url = result.data.webPath; // 获取后台返回的url
                            insertImg(url); // 插入图片，即回显
                        } else {
                            alert("插入图片失败")
                        }
                    }
                }
            },
            getHtml() {
                return this.editor.txt.html();
            },
            setHtml(txt) {
                this.$nextTick(function () {
                    this.editor.txt.html(txt);
                });
            }
        },
        mounted() {
            this.$nextTick(function () {
                this.init();
            });
        }
    }
</script>
<style lang="scss" scoped>
    .home {
        height: 100%;
        width: 100%;
        background-color: white;
        padding: 15px 10px;
    }
    #editor2 {
        width: 100%;
        margin: 0 auto;
        position: relative
    }
</style>
