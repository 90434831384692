<template>
    <div class="row-container">
            <!-- 搜索查询 -->
            <el-row class="query-container">
                <el-col :span="24">
                    <el-form ref="formInline" :inline="true">
                        <el-form-item label="">
                            <el-input v-model="queryParams.keyword" placeholder="请输入查询关键字"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row class="table-container">
                <el-col>
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        fit
                        stripe
                        highlight-current-row
                        style="width: 100%;"
                        ref="queryTable"
                        :indent="20"
                    >
                        <el-table-column label="学员报名时间" prop="createTime" min-width="140px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="机构" prop="enroll.companyName" min-width="140px" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="工种" prop="enroll.gnvqName"></el-table-column>
                        <el-table-column label="等级" prop="enroll.levelName"></el-table-column>
                        <el-table-column label="班级" prop="enroll.className" min-width="150px"></el-table-column>
                        <el-table-column label="学员姓名" prop="enroll.trueName"></el-table-column>
                        <el-table-column label="身份证号" prop="enroll.idCard" min-width="150px"></el-table-column>
                        <el-table-column label="手机号" prop="enroll.phone" min-width="100px"></el-table-column>
                        <el-table-column label="报名状态" prop="dataStatus" min-width="100px">
                            <template slot-scope="{row}">
                                <el-tag :type="row.enroll.confirmStatus | colorFilter">
                                    {{ row.enroll.confirmStatus  | dataStatusFilter}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="报名金额" prop="payValue" align="right" width="100px"></el-table-column>
                        <el-table-column label="分成类型" prop="rateType" align="center" width="100px">
                            <template slot-scope="{row}">
                                <el-tag :type="row.rateType | rateTypeColorFilter">
                                    {{ row.rateType  | rateTypeFilter}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="比例%/金额(元)" prop="rateValue" align="right" width="120px"></el-table-column>
                        <el-table-column label="分成金额" prop="amountValue"  align="right" width="100px"></el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <!-- 分页组件 -->
            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                        @pagination="initTable"/>
            <!-- 列表内容 -->
    </div>
</template>

<script>
    import Pagination from '@/components/Pagination';
    export default {
        components: {
            Pagination,
        },
        filters: {
            dataStatusFilter(status) {
                const statusMap = {
                    9: '未报名',
                    0: '审核中',
                    1: '已审核',
                };
                return statusMap[status]
            },
            rateTypeFilter(status) {
                const statusMap = {
                    1: '比例',
                    2: '固定金额',
                };
                return statusMap[status]
            },
            rateTypeColorFilter(status) {
                const statusMap = {
                    1: 'danger',
                    2: 'success'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: 0,
                    acountId: '',
                    orgId: ''
                },
                //列表数据
                tableData: [],
                tableLoading: false,
            };
        },
        mounted () {
            //this.initTable();
        },
        computed: {
        },
        methods: {
            initData: function (userId, orgId) {
                this.queryParams.userId = userId;
                this.queryParams.orgId = orgId;
                this.tableData = []
                this.$nextTick(function() {
                    this.refreshTable();
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('sale/record/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
