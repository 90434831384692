<template>
    <div class="row-container">
            <!-- 搜索查询 -->
            <el-row class="query-container">
                <el-col :span="24">
                    <el-form :inline="true">
                        <el-form-item label="">
                            <el-input v-model="queryParams.keyword" placeholder="请输入查询关键字"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row class="table-container">
                <el-col>
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        fit
                        stripe
                        highlight-current-row
                        style="width: 100%;"
                        ref="queryTable"
                        :indent="20"
                    >
                        <el-table-column label="发生日期" prop="createTime" width="140px" align="left"></el-table-column>
                        <el-table-column label="发生金额" prop="amount" width="140px" align="right"></el-table-column>
                        <el-table-column label="备注" prop="remarks" align="left" :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <!-- 分页组件 -->
            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                        @pagination="initTable"/>
            <!-- 列表内容 -->
    </div>
</template>

<script>
    import Pagination from '@/components/Pagination';
    export default {
        name: "AccountDetailList",
        components: {
            Pagination,
        },
        data () {
            return {
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: 0,
                    acountId: '',
                    orgId: ''
                },
                //列表数据
                tableData: [],
                tableLoading: false,
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            initData: function (acountId, orgId) {
                this.queryParams.acountId = acountId;
                this.queryParams.orgId = orgId;
                this.tableData = []
                this.$nextTick(function() {
                    this.refreshTable();
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('sale/acount/detail/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>

