<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 工种分类 -->
            <el-col :span="5">
                <el-card class="box-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>职业/工种分类</span>
                    </div>
                    <el-table
                        fit
                        highlight-current-row
                        :show-header="false"
                        :data="categoryList"
                        @row-click="cellClick"
                        style="width: 100%">
                        <el-table-column
                            prop="categoryName"
                            label="">
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>职业/工种列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.useStatus" placeholder="请选择可用状态" style="width:120px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="禁用" value="0"></el-option>
                                        <el-option label="可用" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择储存状态" style="width:120px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                row-key="gnvqId"
                                :indent="20"
                            >
                                <el-table-column label="编号" prop="gnvqCode" width="100px" align="left"></el-table-column>
                                <el-table-column label="名称" prop="gnvqName" min-width="180px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="类型" width="100px" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.gnvqType  | gnvqTypeFilter}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="分类" prop="category.categoryName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种等级" prop="" min-width="100px" align="center" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <el-dropdown v-if="row.gnvqType==='0002'">
                                            <span class="el-dropdown-link">
                                                ({{row.levelList.length}})<i class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu  slot="dropdown">
                                                <el-dropdown-item v-for="(item, idx) in row.levelList" :key="idx">{{item.levelName}}</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                                <el-table-column label="可用状态" prop="" min-width="100px" align="center" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.useStatus"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toLock(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="删除状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="180px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="200" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.gnvqId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.gnvqId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===0 && row.gnvqType==='0002'" type="text" size="mini" @click="toLevelEditor(row.gnvqId)">等级管理</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.gnvqId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="职业/工种编辑"
            :visible.sync="addDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="addDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="addForm" label-width="140px" label-suffix=":">
                    <el-row>
                        <el-divider content-position="left">基本信息</el-divider>
                        <el-col :span="12">
                            <el-form-item label="职业/工种分类" prop="categoryId">
                                <el-select v-model="info.categoryId"  @change="getGnvqSelectList">
                                    <el-option value="" label="请选择"></el-option>
                                    <el-option v-for="item in categoryList" :value="item.categoryId" :label="item.categoryName" :key="item.categoryId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="类型" prop="gnvqType">
                                <el-radio-group v-model="info.gnvqType" @change="typeChange">
                                    <el-radio label="0001">职业</el-radio>
                                    <el-radio label="0002">工种</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="所属职业" prop="parentId" v-if="info.gnvqType==='0002'">
                                <el-select v-model="info.parentId">
                                    <el-option :value="0" label="默认"></el-option>
                                    <el-option v-for="item in gnvqParentList" :value="item.gnvqId" :label="item.gnvqName" :key="item.gnvqId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="职业/工种编号" prop="gnvqCode">
                                <el-input v-model="info.gnvqCode" placeholder="请输入职业/工种编号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="职业/工种名称" prop="gnvqName">
                                <el-input v-model="info.gnvqName" placeholder="请输入职业/工种名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="序号" prop="orderNum">
                                <span slot="label">
                                    序号：
                                    <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                        <i class="el-icon-question" style="color:red"></i>
                                    </el-tooltip>
                                </span>
                                    <el-input v-model="info.orderNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-divider content-position="left">工种描述</el-divider>
                            <editor ref="editor" :height="300"></editor>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="addDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('addForm')">保存</el-button>
            </div>
        </el-drawer>
        <el-drawer
            title="等级信息编辑"
            :visible.sync="levelDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="addDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="levelInfo" :rules="levelValidate" ref="levelForm" label-width="150px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="等级名称" prop="levelName">
                                <el-input v-model="levelInfo.levelName" placeholder="请输入等级名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="对应字典等级" prop="dictId">
                                <el-select v-model="levelInfo.dictId">
                                    <el-option value="" label="请选择"></el-option>
                                    <el-option v-for="item in levelList" :value="item.dictId" :label="item.dictName" :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="报名金额" prop="amount">
                                <el-input v-model="levelInfo.amount" placeholder="请输入报名金额">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="序号" prop="orderNum">
                              <span slot="label">
                                  序号：
                                  <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                    <i class="el-icon-question" style="color:red"></i>
                                  </el-tooltip>
                              </span>
                                <el-input v-model="levelInfo.orderNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="是否有有效期" prop="periodLimit">
                                <el-radio-group v-model="levelInfo.periodLimit">
                                    <el-radio :label="0">有效期</el-radio>
                                    <el-radio :label="1">长期有效</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="有效期月数" prop="periodDays">
                                <el-input v-model="levelInfo.periodDays" placeholder="有效期月数">
                                    <template slot="append">月</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="复审期限月数" prop="reviewDays">
                                <el-input v-model="levelInfo.reviewDays" placeholder="复审期限月数">
                                    <template slot="append">月</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="是否到期/复审提醒" prop="remindFlag">
                                <el-radio-group v-model="levelInfo.remindFlag">
                                    <el-radio :label="0">不提醒</el-radio>
                                    <el-radio :label="1">提醒</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="到期前多少个月提醒" prop="remindDays">
                                <el-input v-model="levelInfo.remindDays" placeholder="到期前多少个月提醒">
                                    <template slot="append">月</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="下一等级报考月数" prop="nextLevelDays">
                                <el-input v-model="levelInfo.nextLevelDays" placeholder="下一等级报考月数">
                                    <template slot="append">月</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
<!--                    <el-row :gutter="32">-->
<!--                        <el-divider content-position="left">报考条件和要求</el-divider>-->
<!--                        <el-col :span="8">-->
<!--                            <el-form-item label="学历证书" prop="eduRequire">-->
<!--                                <el-radio-group v-model="levelInfo.eduRequire">-->
<!--                                    <el-radio :label="0">不需要</el-radio>-->
<!--                                    <el-radio :label="1">需要</el-radio>-->
<!--                                </el-radio-group>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="8">-->
<!--                            <el-form-item label="工龄证明" prop="workRequire">-->
<!--                                <el-radio-group v-model="levelInfo.workRequire">-->
<!--                                    <el-radio :label="0">不需要</el-radio>-->
<!--                                    <el-radio :label="1">需要</el-radio>-->
<!--                                </el-radio-group>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="8">-->
<!--                            <el-form-item label="证书" prop="certRequire">-->
<!--                                <el-radio-group v-model="levelInfo.certRequire">-->
<!--                                    <el-radio :label="0">不需要</el-radio>-->
<!--                                    <el-radio :label="1">需要</el-radio>-->
<!--                                </el-radio-group>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                    <el-row>
                        <el-col :span="24">
                            <editor2 ref="levelEditor" :height="300"></editor2>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col style="text-align: center">
                            <el-button  type="primary" @click="reset('levelForm')">重置</el-button>
                            <el-button  type="primary" @click="saveLevel('levelForm')">保存</el-button>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" class="table-container">
                        <el-divider content-position="left">等级列表</el-divider>
                        <el-col>
                            <el-table
                                v-loading="tableLoading2"
                                :data="levelTableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                :indent="20"
                            >
                            <el-table-column
                                label="#"
                                type="index"
                                width="50">
                            </el-table-column>
                                <el-table-column label="等级名称" prop="levelName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="报名金额" prop="amount" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="有效类型" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.periodLimit | colorFilter">
                                            {{ row.periodLimit  | periodLimitFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="是否提醒" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.remindFlag | colorFilter">
                                            {{ row.remindFlag  | remindFlagFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="删除状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEditLevel(row.levelId)">编辑</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDeleteLevel(row.levelId,row.gnvqId,1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDeleteLevel(row.levelId,row.gnvqId, 0)">恢复</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toPointEditor(row.levelId, row.categoryId, row.gnvqId)">知识点</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button  type="primary" @click="closeLevelDrawer()">保存</el-button>
            </div>
        </el-drawer>
        <GnvqLevelPointEditor ref="pointEditor"></GnvqLevelPointEditor>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqLevelPointEditor from "./GnvqLevelPointEditor";
    import editor from '../../components/editor/editor'
    import editor2 from '../../components/editor/editor2'
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqLevelPointEditor,
            editor,
            editor2,
        },
        filters: {
            gnvqTypeFilter(code) {
                const statusMap = {
                    '0001': '职业',
                    '0002': '工种',
                };
                return statusMap[code]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
            periodLimitFilter(status) {
                const statusMap = {
                    0: '期限有效',
                    1: '长期有效',
                };
                return statusMap[status]
            },
            remindFlagFilter(status) {
                const statusMap = {
                    0: '否',
                    1: '是',
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('输入序号'));
                } else if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值型数据'));
                } else {
                    callback();
                }
            };
            const validateAmount = (rule, value, callback) => {
                let reg = /^\d+\.?\d{0,2}$/;
                if (value) {
                    if (!reg.test(value)) {
                        callback(new Error('仅支持数字和小数点后两位'));
                        return;
                    }
                    callback();
                    return;
                } else if (value === 0) {
                    callback();
                    return;

                } else {
                    callback(new Error('报名费用格式不正确'));
                    return;
                }
            };
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '工种管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    categoryId: '',
                    useStatus: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //动态下拉菜单
                categoryList: [],
                gnvqParentList: [],
                //新增表单
                addDrawer: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    categoryId: [
                        { required: true, type: 'number', message: '请选择所属类型', trigger: 'change' }
                    ],
                    parentId: [
                        { required: true, type: 'number', message: '请选择所属上级', trigger: 'change' }
                    ],
                    gnvqType: [
                        { required: true, message: '请选择类型', trigger: 'change' }
                    ],
                    gnvqCode: [
                        { required: true, message: '请输入工种编号', trigger: 'blur' }
                    ],
                    gnvqName: [
                        { required: true, message: '请输入工种名称', trigger: 'blur' }
                    ],
                    orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ],
                },
                //登记信息编辑表单
                levelDrawer: false,
                //等级管理、编辑表单
                levelTableData: [],
                levelInfo: {
                    levelName: ''
                },
                levelList: [],
                tableLoading2: false,
                levelValidate: {
                    // dictId: [
                    //     { required: true, type: 'number', message: '请选择等级', trigger: 'blur' }
                    // ],
                    levelName: [
                        { required: true, message: '请输入等级名称', trigger: 'blur' }
                    ],
                    amount: [
                        { required: true, validator: validateAmount, message: '请输入报名金额', trigger: 'blur' }
                    ],

                    periodLimit: [
                        { required: true, type: 'number', message: '请选择是否有有效期', trigger: 'blur' }
                    ],
                    remindFlag: [
                        { required: true, type: 'number', message: '请选择是否到期/复审提醒', trigger: 'blur' }
                    ],
                    orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ]
                },
            };
        },
        mounted () {
            this.getCategoryList();
            this.getLevelList();
            this.initTable();
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('gnvq/info/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getCategoryList: function () {
                this.apis.get('gnvq/category/getList?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.categoryList = res.data;
                            console.log(this.categoryList);
                            this.categoryList.unshift({ categoryId: '', categoryName: "全部" });
                            console.log(this.categoryList);
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            cellClick: function(row) {
                this.queryParams.categoryId = row.categoryId;
                this.refreshTable();
            },
            getLevelList: function () {
                this.apis.get('system/dict/getList?typeCode=0002')
                    .then((res) => {
                        if (res.code === 1) {
                            this.levelList = res.data;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //改变可用状态
            toLock: function (row) {
                let data = {
                    gnvqId: row.gnvqId,
                    useStatus: row.useStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('gnvq/info/updUseStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toAdd: function () {
                this.info = {
                    categoryId: this.queryParams.categoryId,
                    gnvqType: '0002',
                    parentId: 0,
                    orderNum: 0
                };
                this.$nextTick(function() {
                    this.$refs.editor.setHtml("");
                });
                this.getGnvqSelectList()
                this.addDrawer = true;
            },
            typeChange: function (val) {
                if (val==='0001') {
                    this.info.parentId = 0;
                }
            },
            getGnvqSelectList: function () {
                if (this.info.categoryId) {
                    this.gnvqParentList = []
                    this.apis.get('gnvq/info/getList?gnvqType=0001&categoryId=' + this.info.categoryId)
                        .then((res) => {
                            if (res.code === 1) {
                                this.gnvqParentList = res.data || [];
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "gnvq/info/save";
                    this.info.gnvqDesc = this.$refs.editor.getHtml();
                    this.info.orgId = this.appCookies.getCookieOID();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.addDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('gnvq/info/getById?gnvqId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.addDrawer = true;
                            this.$nextTick(function() {
                                this.$refs.editor.setHtml(this.info.gnvqDesc);
                                this.getGnvqSelectList();
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        gnvqId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('gnvq/info/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //打开等级信息编辑侧边栏
            toLevelEditor: function (keyId) {
                this.refreashLevel(keyId);
                this.levelDrawer = true;
                this.$nextTick(function() {
                    this.$refs.levelEditor.setHtml("");
                });
            },
            refreashLevel: function (keyId) {
                this.levelInfo = {
                    gnvqId: keyId,
                };
                this.apis.get('gnvq/level/getAllList?gnvqId=' + keyId + '&orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.levelTableData = res.data;
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //重置侧边栏表单信息
            reset: function () {
                let keyId = this.levelInfo.gnvqId;
                this.levelInfo = {
                    gnvqId: keyId
                };
            },
            saveLevel: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "gnvq/level/save";
                        this.levelInfo.orgId = this.appCookies.getCookieOID();
                        this.levelInfo.levelDesc = this.$refs.levelEditor.getHtml();
                        this.levelInfo.createBy = this.appCookies.getCookieUID();
                        this.levelInfo.createByname = this.appCookies.getCookieUNAME() ;
                        this.levelInfo.modifyBy = this.appCookies.getCookieUID();
                        this.levelInfo.modifyByname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.levelInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$refs.levelEditor.setHtml('');
                                    this.refreashLevel(this.levelInfo.gnvqId);
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            //编辑等级列表
            toEditLevel: function (keyId) {
                this.apis.get('gnvq/level/getById?levelId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.levelInfo = res.data;
                            this.$nextTick(function() {
                                this.$refs.levelEditor.setHtml(this.levelInfo.levelDesc);
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDeleteLevel: function (levelId, gnvqId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        levelId: levelId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('gnvq/level/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.refreashLevel(gnvqId);
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //关闭等级信息编辑侧边栏
            closeLevelDrawer: function () {
                this.levelDrawer = false;
                this.initTable();
            },
            //打开编辑知识点侧边栏
            toPointEditor: function (levelId, gnvqCatId, gnvqId) {
                this.$refs.pointEditor.setData(gnvqCatId, gnvqId);
                this.$refs.pointEditor.show(2, levelId, '');
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
