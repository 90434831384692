
<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>账户列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true" label-width="80px">
                                <el-form-item label="关键字">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item label="培训机构"  v-if="this.privRange.havePriv()">
                                    <el-select filterable v-model="queryParams.orgId" placeholder="请选择培训机构">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="推广员姓名" prop="trueName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="手机号" prop="phone" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="培训机构" prop="orgName" min-width="200px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="收入金额" prop="inAmount" align="right" width="100px"></el-table-column>
                                <el-table-column label="转出金额" prop="outAmount" align="right" width="100px"></el-table-column>
                                <el-table-column label="余额" prop="outAmount" align="right" width="100px">
                                    <template slot-scope="{ row }">
                                        <span>{{row.inAmount-row.outAmount}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="180" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini" @click="toOutCome(row.acountId)">转出</el-button>
                                        <el-button type="text" size="mini" @click="toShowDetail(row.acountId, row.orgId)">账户明细</el-button>
                                        <el-button type="text" size="mini" @click="toShowRecord(row.userId, row.orgId)">推广记录</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="金额转出"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="手机号" prop="phone">
                            {{info.userInfo.phone}}
                        </el-form-item>
                        <el-form-item label="真实姓名" prop="trueName">
                            {{info.userInfo.trueName}}
                        </el-form-item>
                        <el-form-item label="培训机构" prop="trueName">
                            {{info.orgName}}
                        </el-form-item>
                        <el-form-item label="当前余额" prop="userPwd">
                            {{info.inAmount - info.outAmount}}
                        </el-form-item>
                        <el-form-item label="转出金额" prop="amount">
                            <el-input  v-model="info.amount"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">确认转出</el-button>
            </span>
        </el-dialog>
        <el-drawer
            title="账户明细"
            :visible.sync="viewDetailDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="60%"
        >
            <div class="demo-drawer__content">
                <AccountDetailList ref="accountDetailList"></AccountDetailList>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="viewDetailDrawer = false">关闭</el-button>
            </div>
        </el-drawer>
        <el-drawer
            title="推广记录"
            :visible.sync="viewRecordDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <RecordList ref="recordList"></RecordList>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="viewRecordDrawer = false">关闭</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import _ from 'lodash'
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import AccountDetailList from "./components/AccountDetailList";
    import RecordList from "./components/RecordList";
    export default {
        name: "AccountQuery",
        components: {
            Pagination,
            Breadcrumb,
            AccountDetailList,
            RecordList,
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '账户管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    userId: this.appCookies.getCookieUID(),
                    orgId: this.appCookies.getCookieOID(),
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //动态下拉菜单
                orgList: [],
                //编辑、新增表单
                editDialog: false,
                info: {
                    userInfo: {}
                },
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    amount: [
                        { required: true, message: '请输入转出金额', trigger: 'blur' }
                    ],
                },
                viewDetailDrawer: false,
                viewRecordDrawer: false,
            };
        },
        mounted () {
            this.getOrgList();
            this.initTable();
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('sale/acount/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getOrgList: _.debounce(function (val) {
                this.orgFinding = true;
                setTimeout(() => {
                    this.orgFinding = false;
                    this.apis.get('system/org/getList?keyword=' + val + '&orgId=' + this.appCookies.getCookieOID())
                        .then((res) => {
                            if (res.code === 1) {
                                this.orgList = res.data || []
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }, 200)
            }, 500),
            toShowDetail: function (acountId, orgId) {
                this.viewDetailDrawer = true;
                this.$nextTick(function() {
                    this.$refs.accountDetailList.initData(acountId, orgId);
                });
            },
            toShowRecord: function (userId, orgId) {
                this.viewRecordDrawer = true;
                this.$nextTick(function() {
                    this.$refs.recordList.initData(userId, orgId);
                });
            },
            toOutCome: function (acountId) {
                this.apis.get('sale/acount/getById?acountId=' + acountId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                            // this.info.bannerType = this.info.bannerType.toString()
                            // console.log(this.jsonValue)
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toAdd: function () {
                this.info = {
                    nameId: '',
                    name: ''
                };
                console.log(this.info);
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "sale/acount/turnout";
                    this.info.businessId = 'AI-TURN-OUT';
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('xxx/port' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (keyId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        keyId: keyId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/video/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>

